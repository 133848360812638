
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Alert, BiblioteksentralenProvider, Box, Button, colors, Grid, Link, VStack } from "@biblioteksentralen/react";
import { storeFeatureFlags } from "@forrigebok/common";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import { useMount } from "react-use";
import { useTranslation } from "src/utils/useTranslation";
import { HideOnPrint, OnlyShowOnPrint } from "../components/HideOnPrint";
import Footer from "../components/layout/footer/footer";
import Header, { Logo } from "../components/layout/header/Header";
import NewVersionAlert from "../components/NewVersionAlert";
import Onboarding from "../components/onboarding/Onboarding";
import { useVelkommenTilForrigebokToast } from "../components/onboarding/useVelkommenTilForrigebokToast";
import SEO from "../components/SEO";
import { CenteredSpinner } from "../components/SWRStatus";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import Varsel from "../components/Varsel";
import { PlausibleSetup } from "../utils/analytics/Plausible";
import { forrigebokTheme } from "../utils/forrigebokTheme";
import { GlobalStyling } from "../utils/GlobalStyling";
import { logVercelInfo } from "../utils/logVercelInfo";
import { useUser } from "../utils/useUser";
const independentAppRoutes = ["/apidoc", "/cms"];
function AppProviders(props: AppProps) {
    const isIndependentApp = independentAppRoutes.some((route) => props.router.asPath.startsWith(route));
    useMount(() => {
        logVercelInfo();
        storeFeatureFlags();
    });
    const { Component, pageProps } = props;
    if (isIndependentApp)
        return <Component {...pageProps}/>;
    return (<SessionProvider session={(pageProps as any).session}>
      <PlausibleSetup />
      <SEO />
      <BiblioteksentralenProvider customTheme={forrigebokTheme}>
        <GlobalStyling />
        <Testenvironmentbanner />
        <OnlyShowOnPrint>
          <Logo />
        </OnlyShowOnPrint>
        <App {...props}/>
        <NewVersionAlert />
      </BiblioteksentralenProvider>
    </SessionProvider>);
}
// Hopper over onboarding for noen paths. Dette gjør innholdet tilgjengelig for google og for uinnloggede brukere. Gjør dessuten loading litt raskere fordi vi ikke trenger å vente på useUser() før vi viser noe innhold.
// TO: Onboarding sørger ikke for sikkerhet/tilgangskontroll siden Onboarding skjer client-side, så det er ingen sikkerhetsrisko ved å hoppe over Onboarding. Tilgangskontroll skjer i api'ene og/eller i SSR
const publicPaths = ["/vokabular", "/info", "/dashboard", "/nyheter", "/dev"];
function App({ Component, pageProps, router }: AppProps) {
    const isPublicPath = publicPaths.some((path) => router.pathname.startsWith(path));
    const user = useUser();
    useVelkommenTilForrigebokToast();
    // Tidlig return her pga at sider som er offentlig tilgjengelig (ligger i isPublicPath) må rendres SSR for at google skal kunne ineksere og at open graph data skal være tilgjengelig for sosiale medier
    if (user.onBoardingFinished || isPublicPath) {
        return (<Grid templateRows="auto 1fr auto" minHeight="100vh">
        <Header />
        <Box as="main" position="relative" display="flex" flexDirection="column" justifyContent="space-between">
          <Box marginTop="5vmin" marginBottom="20vh">
            <Varsel marginBottom="2rem"/>
            <Component {...pageProps}/>
            {!user.onBoardingFinished && <KnappTilInnlogging />}
          </Box>
          <TaKontakt />
        </Box>
        <Footer />
      </Grid>);
    }
    if (user.loading)
        return <CenteredSpinner />;
    return (<Grid templateRows="1fr auto" minH="100vh">
      <Box as="main" backgroundColor={colors.neptune[700]} color="white">
        <Varsel />
        <Onboarding />
      </Box>
      <Footer />
    </Grid>);
}
function KnappTilInnlogging() {
    const { t } = useTranslation();
    return (<VStack marginTop="15vh" spacing="1rem">
      <Alert status="warning" variant="inline">
        {t("Du er ikke logget inn")}
      </Alert>
      <Button as="a" href="/">
        {t("Gå til innlogging for å komme i gang")}
      </Button>
    </VStack>);
}
const TaKontakt = () => {
    const { t } = useTranslation();
    return (<HideOnPrint display="flex" justifyContent="flex-end" pointerEvents="none" position={{ md: "sticky" }} bottom={0}>
      <Link marginX="1rem" pointerEvents="auto" padding=".25rem .75rem" borderTopRadius="md" fontWeight={600} backgroundColor={colors.neptune[500]} color="white" variant="plain" href="https://forms.office.com/r/BQ4Bnvtr8b">
        {t("Gi tilbakemelding")}
      </Link>
    </HideOnPrint>);
};
const __Next_Translate__Page__193e424ee48__ = AppProviders;

    export default __appWithI18n(__Next_Translate__Page__193e424ee48__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  