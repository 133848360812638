
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { formaterDato } from "@biblioteksentralen/utils";
import { Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { slugifyString } from "@forrigebok/common";
import { News } from "@forrigebok/generatedtypes";
import { GetStaticPaths, GetStaticProps } from "next";
import { groq } from "next-sanity";
import NextLink from "next/link";
import { useTranslation } from "src/utils/useTranslation";
import { ChevronLink } from "../../components/ChevronLink";
import RichText from "../../components/RichText";
import SEO from "../../components/SEO";
import { logClick } from "../../utils/analytics/Plausible";
import { notDraft } from "../../utils/groqFilters";
import { cdnSanityClient } from "../../utils/sanity";
export const getStaticPaths: GetStaticPaths = async () => {
    const data: News[] = await cdnSanityClient.fetch(groq `*[_type == "news" && ${notDraft}] | order(_createdAt desc)`);
    return {
        paths: data.map((nyhet) => getNyhetUrl(nyhet)),
        fallback: "blocking"
    };
};
type Props = {
    nyhet: News;
};
export const getNyhetUrl = (nyhet: Pick<News, "_id" | "title">) => `/nyheter/${slugifyString(nyhet.title ?? "")};${nyhet._id}`;
const getStaticProps: GetStaticProps<Props> = async (ctx) => {
    const id = typeof ctx.params?.id == "string"
        ? // Split på ";" fordi første del av url før ";" kun brukes for human-readable tittel
            ctx.params.id.split(";").at(-1)
        : undefined;
    if (!id)
        return { notFound: true };
    const nyhet: News = await cdnSanityClient.fetch(groq `*[_id == $id && ${notDraft}][0]`, { id });
    return {
        props: { nyhet },
        revalidate: 30
    };
};
const Nyhet = ({ nyhet }: Props) => {
    const { t } = useTranslation();
    return (<>
      <SEO title={nyhet.title}/>
      <Container maxW="container.md" marginBottom="3rem">
        <ChevronLink as={NextLink} href="/nyheter" onClick={() => logClick("Nyheter: Alle nyheter")}>
          {t("Alle nyheter")}
        </ChevronLink>
      </Container>
      <Container maxW="container.sm">
        <Stack as="article">
          <Box>
            <Heading as="h1" size="xl">
              {nyhet.title}
            </Heading>
            <Text as="time" opacity={0.7}>
              {t("Publisert: {dato}", { dato: formaterDato(nyhet._createdAt, "PP") })}
            </Text>
          </Box>
          <RichText fontSize="lg" blocks={nyhet.body} headingsProps={{ fontSize: "xl", fontWeight: "bold" }}/>
        </Stack>
      </Container>
    </>);
};
export default Nyhet;

    async function __Next_Translate__getStaticProps__193e424eef3__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/nyheter/[id]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193e424eef3__ as getStaticProps }
  