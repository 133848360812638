import { colors, Container, Grid, Heading, LinkBox, LinkOverlay, Stack, Text } from "@biblioteksentralen/react";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import NextLink from "next/link";
import { BannerNews } from "src/pages/api/internal/seasonalbanners-config.api";
import { getNyhetUrl } from "src/pages/nyheter/[id].page";
import { dateFnsLocaleMap } from "src/utils/dateHelpers";
import { useTranslation } from "src/utils/useTranslation";
import SanityImage from "../RichTextBilde";

export const NyhetsBanner = (props: { news: BannerNews }) => {
  const { news } = props;
  const image = news.body?.find((block) => block._type === "bilde");
  const { t, lang } = useTranslation();

  return (
    <Container maxW="container.lg" position="relative" display="flex" flexDirection="column">
      <LinkBox
        role="group"
        backgroundColor={colors.lightPurple}
        borderRadius="lg"
        overflow="hidden"
        padding={{ base: "1rem", sm: "1.5rem" }}
      >
        <Grid gap={{ base: "1rem" }}>
          {image && (
            <SanityImage
              _groupHover={{ transform: "scale(1.025)" }}
              transition="1s"
              hideCaption
              bilde={image}
              width={1000}
            />
          )}
          <Stack spacing=".15rem">
            <Text as="time" fontSize="sm" opacity={0.7} fontWeight={600}>
              {t("{timeAgo} siden", {
                timeAgo: formatDistanceToNowStrict(new Date(news._createdAt), {
                  locale: dateFnsLocaleMap[lang],
                }),
              })}
            </Text>
            <LinkOverlay as={NextLink} href={getNyhetUrl(news)}>
              <Heading as="h2" size={{ base: "md", lg: "lg" }} _groupHover={{ textDecoration: "underline" }}>
                {news.title}
              </Heading>
            </LinkOverlay>
            <Text fontSize={{ md: "lg" }}>{news.excerpt}</Text>
          </Stack>
        </Grid>
      </LinkBox>
    </Container>
  );
};
