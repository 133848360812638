import { colors, Container, Flex, Heading, LinkBox, LinkOverlay, Skeleton, Text } from "@biblioteksentralen/react";
import { formatDistanceToNowStrict } from "date-fns";
import NextLink from "next/link";
import { dateFnsLocaleMap } from "src/utils/dateHelpers";
import { useTranslation } from "src/utils/useTranslation";
import { getNyhetUrl } from "../../pages/nyheter/[id].page";
import { logClick } from "../../utils/analytics/Plausible";
import Feilmelding from "../Feilmelding";
import { useForsideData } from "./useForsideData";

const forsideNyhetFarge = "#B9C6EA";

function ForsideNyhet() {
  const { data, error } = useForsideData();
  const { t, lang } = useTranslation();

  if (error) return <Feilmelding feilmelding={error} />;
  if (!data) return <Skeleton height="6rem" borderRadius={{ md: "md" }} endColor={forsideNyhetFarge} />;

  const forsideNyhet = data?.nyhet;
  if (!forsideNyhet) return null;

  return (
    <LinkBox
      backgroundColor="#D9E2FF"
      _hover={{ backgroundColor: forsideNyhetFarge }}
      padding={{ base: "1rem", sm: "1.25rem" }}
      borderRadius={{ md: "md" }}
      transition=".2s"
    >
      <Flex justifyContent="space-between" marginBottom=".5rem">
        <Text fontSize="xs" backgroundColor={colors.neptune[600]} padding=".25em .75em" borderRadius="xl" color="white">
          {t("Nyheter og inspirasjon")}
        </Text>
        <Text as="time" fontSize="sm" opacity={0.7} fontWeight={600}>
          {t("{timeAgo} siden", {
            timeAgo: formatDistanceToNowStrict(new Date(forsideNyhet._createdAt), { locale: dateFnsLocaleMap[lang] }),
          })}
        </Text>
      </Flex>
      <LinkOverlay as={NextLink} href={getNyhetUrl(forsideNyhet)} onClick={() => logClick("Nyheter: Forsidenyhet")}>
        <Heading as="h2" size="md">
          {forsideNyhet.title}
        </Heading>
      </LinkOverlay>
    </LinkBox>
  );
}

const ForsideNyhetWrapper = () => (
  <Container maxW="container.lg" paddingX={{ md: ".75rem" }}>
    <ForsideNyhet />
  </Container>
);

export default ForsideNyhetWrapper;
